/* Global Styles */
* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    background: #f7f7f7;
    color: #333;
  }
  
  /* Container for the entire page */
  .subscribe-container {
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding: 40px 20px;
    background: #f7f7f7;
  }
  
  /* Common styles for both form sections */
  .form-section {
    background: #ffffff;
    padding: 2.5rem;
    margin-bottom: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
  }
  
  /* Form headings */
  .form-section h1,
  .form-section h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #333;
    text-align: center;
  }
  
  /* Form styling */
  .form-section form {
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
  }
  
  /* Labels */
  label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.2rem;
    font-weight: 500;
  }
  
  /* Input fields */
  input[type="text"],
  input[type="number"],
  textarea,
  select {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff;
    transition: border-color 0.3s ease;
  }
  
  input[type="text"]:focus,
  input[type="number"]:focus,
  textarea:focus,
  select:focus {
    border-color: #4caf50;
    outline: none;
  }
  
  /* Buttons */
  button,
  .subscribe-btn {
    padding: 0.8rem 1.5rem;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
    margin-top: 1rem;
  }
  
  button:hover,
  .subscribe-btn:hover {
    background-color: #43a047;
  }
  
  /* Info icon */
  .info-icon {
    cursor: pointer;
    margin-left: 5px;
    color: #4caf50;
    font-size: 1rem;
  }
  
  /* Donation info popup */
  .donation-info-popup {
    background-color: #f9f9f9;
    border-left: 4px solid #4caf50;
    padding: 12px;
    margin-top: 10px;
    border-radius: 6px;
    color: #333;
    font-size: 0.95rem;
  }
  
  /* Order total display */
  .order-total {
    margin-top: 20px;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
  }
  
  /* Nearest site display */
  .nearest-site {
    margin-top: 15px;
    font-weight: bold;
    text-align: center;
  }
  
  /* No Cycle Message */
  .no-cycle-message-container {
    display: flex;
    flex-direction: column; 
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    padding-top: 5rem;
  }
  
  .no-cycle-message {
    text-align: center;
    background: #fff;
    padding: 2.5rem;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  
  .no-cycle-message h1 {
    font-family: 'Indie Flower', cursive;
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
    color: #2f4f4f;
    font-weight: 600;
  }
  
  .no-cycle-message p {
    font-family: 'Lora', serif;
    font-size: 1.1rem;
    margin-bottom: 2.5rem;
    font-weight: 400;
    color: #555;
  }
  
  .no-cycle-message button {
    padding: 0.9rem 2rem;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .no-cycle-message button:hover {
    background-color: #43a047;
  }
  
  /* Loading message styling */
  .loading-message {
    background: #fff;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
  }
  
  /* Late Subscription Warning Popup Styles */
  .late-subscription-warning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .late-subscription-warning {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    text-align: center;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }
  
  .late-subscription-warning p {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #333;
  }
  
  .late-subscription-warning button {
    padding: 0.6rem 1.2rem;
    background-color: #4caf50;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .late-subscription-warning button:hover {
    background-color: #43a047;
  }
  
  /* Persistent Late Sign-Up Banner */
  .late-signup-banner {
    background-color: #ffebee;
    color: #c62828;
    padding: 12px;
    border: 1px solid #c62828;
    border-radius: 6px;
    text-align: center;
    margin: 1.5rem 0;
    font-weight: 600;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .form-section {
      padding: 1.5rem;
      margin-bottom: 1.5rem;
    }
    
    .form-section h1,
    .form-section h2 {
      font-size: 1.8rem;
    }
    
    input[type="text"],
    input[type="number"],
    textarea,
    select {
      font-size: 0.9rem;
      padding: 0.65rem;
    }
    
    button,
    .subscribe-btn {
      font-size: 0.9rem;
      padding: 0.7rem 1.3rem;
    }
  }
  
  @media (max-width: 480px) {
    .form-section h1,
    .form-section h2 {
      font-size: 1.6rem;
    }
    
    input[type="text"],
    input[type="number"],
    textarea,
    select {
      font-size: 0.85rem;
      padding: 0.6rem;
    }
    
    button,
    .subscribe-btn {
      font-size: 0.85rem;
      padding: 0.6rem 1rem;
    }
  }
  