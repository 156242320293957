.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .signup-form {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .signup-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .signup-form label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 600;
  }
  
  .signup-form input {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .signup-form button {
    width: 100%;
    padding: 0.8rem;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .signup-form button:hover {
    background-color: #45a049;
  }
  
  .signup-form p {
    text-align: center;
    margin-top: 1rem;
  }
  
  .signup-form a {
    color: #4caf50;
    text-decoration: none;
    font-weight: bold;
  }
  
  .signup-form a:hover {
    text-decoration: underline;
  }

  .form-logo {
    display: block;
    margin: 0 auto 20px auto; 
    max-width: 150px; 
    height: auto;
  }
  