/* Profile Page Styling */
.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.profile-content {
    text-align: center;
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
}

.profile-title {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    color: #333;
}

.no-subscription {
    font-size: 1.2rem;
    color: #666;
    margin-top: 1.5rem;
}

/* Centering and styling the h2 elements */
.subscription-list h2 {
    text-align: center; 
    font-size: 1.8rem;
    color: #4caf50; 
    margin-bottom: 1rem; 
    font-weight: 600;
}

/* Subscription List Styling */
.subscription-list {
    margin-top: 1.5rem;
}

.subscription-details {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.subscription-details p {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.subscription-details strong {
    color: #4caf50;
    font-weight: 600;
}

hr {
    border: none;
    height: 1px;
    background-color: #ccc;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
    .profile-content {
        padding: 1.5rem;
        max-width: 90%;
    }

    .profile-title {
        font-size: 1.8rem;
    }

    .subscription-details p {
        font-size: 1rem;
    }

    .subscription-list h2 {
        font-size: 1.6rem;
    }
}
