.contact-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; 
  }
  
  .contact-container {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px; 
    width: 100%;
  }
  
  .contact-container h2 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1rem;
    color: #333; 
    text-align: center;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  
  .contact-form label {
    font-size: 1rem;
    color: #333; 
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #f0f0f0; 
    box-sizing: border-box;
  }
  
  .contact-form textarea {
    height: 150px;
  }
  
  .contact-form button {
    padding: 0.8rem 1.5rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #45a049;
  }
  
  .success-message,
  .error-message {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .success-message {
    color: #4caf50;
  }
  
  .error-message {
    color: red;
  }
  