.header-container {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #4caf50;
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem; 
}

.nav-logo {
  font-family: "Indie Flower", cursive;
  font-size: 1.5rem;
  color: #4caf50;
  font-weight: bold;
  text-decoration: none;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-link {
  color: #4caf50;
  font-size: 1rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid #4caf50;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
  background-color: #4caf50;
  color: #fff;
}

.profile-dropdown {
  position: relative;
}

.user-icon {
  font-size: 2rem;
  color: #4caf50;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 90%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
  width: 150px;
  padding: 0.5rem 0; 
}

.dropdown-item {
  display: block;
  padding: 0.8rem 1rem;
  color: #4caf50;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
}

.dropdown-menu button {
  background: none;
  border: none;
  color: #4caf50;
  padding: 0.8rem 1rem;
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.dropdown-menu button:hover {
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
}