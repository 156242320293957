.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-form {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 600;
  }
  
  .login-form input {
    width: 100%; 
    padding: 0.8rem;
    margin-bottom: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .login-form button {
    width: 100%; 
    padding: 0.8rem;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-form button:hover {
    background-color: #45a049;
  }
  
  .login-form p {
    text-align: center;
    margin-top: 1rem;
  }
  
  .login-form a {
    color: #4caf50;
    text-decoration: none;
    font-weight: bold;
  }
  
  .login-form a:hover {
    text-decoration: underline;
  }
  
  .error-message {
    color: #d9534f; 
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
    background-color: #f8d7da; 
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #f5c6cb; 
  }

  .success-message {
    color: #28a745; 
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
    background-color: #d4edda; 
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #c3e6cb; 
  }

  .remember-me {
    display: flex;
    flex-direction: row; 
    align-items: flex-start;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #333;
  }
  
  .remember-me input[type="checkbox"] {
    margin-right: 0.5rem;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  
  .remember-me label {
    cursor: pointer;
    margin-top: 0.1rem;
  }

  .form-logo {
    display: block;
    margin: 0 auto 20px auto; 
    max-width: 150px; 
    height: auto;
  }
  
  .forgot-password-link {
    color: #4caf50;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1rem;
  }
  
  .forgot-password-link:hover {
    color: #45a049;
    text-decoration: underline;
  }
  

  