.admin-dashboard {
  padding: 2rem;
  font-family: 'Arial', sans-serif;
  background-color: #f0f4f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: #2c3e50;
  font-size: 2rem;
  letter-spacing: 1px;
}

section {
  margin-bottom: 2rem;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

h2 {
  margin-bottom: 1rem;
  color: #27ae60;
  font-size: 1.5rem;
}

/* Summary section for egg cycle totals */
.egg-cycle-summary {
  margin-bottom: 1rem;
}

.egg-cycle-summary p {
  font-size: 1.1rem;
  color: #34495e;
  background-color: #ecf0f1;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}

.cycle-summary label {
  font-weight: bold;
  color: #34495e;
}

/* Buttons */
button {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2ecc71;
}

button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

/* Form elements */
input[type="text"],
input[type="number"],
input[type="date"],
select {
  width: 100%;
  padding: 10px;
  margin: 5px 0 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

table th,
table td {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
  color: #333;
}

table th {
  background-color: #27ae60;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

table td {
  color: #7f8c8d;
}

table tr:hover {
  background-color: #f1f1f1;
}

/* Boxed section for forms */
.form-box {
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.site-form label {
  font-weight: bold;
  color: #2c3e50;
}

button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
  opacity: 0.6; 
}

textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  color: #34495e;
  background-color: #ecf0f1;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0;
}

.pagination button {
  padding: 0.3rem 0.6rem;
}

@media (max-width: 768px) {
  table th, table td {
      font-size: 0.9rem;
      padding: 10px;
  }

  h1, h2 {
      font-size: 1.5rem;
  }

  .admin-dashboard {
    padding: 1rem;
  }

  section {
    padding: 1rem;
  }
}


